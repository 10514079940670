<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import wxAction from '@/untils/wx-action'
import services from '@/services/index'
import { mapMutations, mapState } from 'vuex'
export default {
  created () {

  },
  updated () {
    if (window.CLIENT_IS_WECHAT) {
      window.ee.on('signature', (signature) => {
        if (this.userInfo.username) this.getUserPrivXX()
        const wxJsapiConfig = { ...wxAction.siteConfig.wxSDKConfig, ...signature }
        wxAction.config(wxJsapiConfig)
        wxAction.share({
          title: this.$route.meta && this.$route.meta.title,
          link: window.location.href
        })
      }, true)
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    ...mapMutations(['SET_USERINFO']),
    async getUserPrivXX () {
      try {
        const info = await services.user.getUserPrivXX()
        this.SET_USERINFO({
          allowoper: info.allowoper,
          domain: info.domain,
          groupid: info.groupid,
          groupname: info.gpname,
          icon: info.icon,
          mobphone: info.mobphone
        })
      } catch (e) {
      }
    }
  }
}
</script>

<style lang="scss">
</style>
