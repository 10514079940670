import wx from 'weixin-js-sdk'

const CLIENT_IS_WECHAT = /micromessenger/.test(navigator.userAgent.toLowerCase())

const siteConfig = {
  name: '默默通物流',
  description: '欢迎来到默默通智慧物流管理平台。',
  origin: 'https://m.momoton.com',
  imgUrl: 'https://m.momoton.com/assets/images/mmt-logo-single.jpg',
  wxSDKConfig: {
    debug: false,
    jsApiList: [
      'updateAppMessageShareData', 'updateTimelineShareData', 'closeWindow',
      'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo', 'onMenuShareQZone',
      'previewImage', 'hideMenuItems', 'showMenuItems'
    ],
    openTagList: ['wx-open-launch-app']
  }
}

function config (wxJsapiConfig) {
  wx.config(wxJsapiConfig)
}

let isReady = false

function ready (callback) {
  if (typeof callback !== 'function') {
    throw new TypeError('callback must be a function')
  }
  if (!CLIENT_IS_WECHAT) return
  if (isReady) {
    return callback()
  } else {
    wx.ready(() => {
      isReady = true
      callback()
    })
  }
}

function share ({
  title = siteConfig.name, desc = siteConfig.description, link = siteConfig.origin,
  imgUrl = siteConfig.imgUrl, success, cancel
} = {}) {
  const props = { title, desc, link, imgUrl, success, cancel }
  ready(() => {
    wx.updateAppMessageShareData(props)
    wx.updateTimelineShareData(props)
  })
}

function closeWindow () {
  ready(() => wx.closeWindow())
}

function previewImage (imgs = [], current) {
  const config = {
    current: current || imgs[0],
    urls: imgs
  }
  wx.ready(() => wx.previewImage(config))
}

function chooseImage ({ success }) {
  wx.ready(() => wx.chooseImage({
    count: 1, // 默认9
    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
    sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
    success
  })
  )
}

export default { config, share, closeWindow, siteConfig, previewImage, chooseImage }
