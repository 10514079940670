import service, { fileService } from '@/untils/axios-config'

const request = (fileName, actionname, data = {}) => {
  return service.post(`${fileName}/unifyCallFunc`, { actionname, ...data }, { headers: { Methdname: actionname } })
}

const filerequest = (fileName, actionname, formData) => {
  return fileService.post(`${fileName}/${actionname}`, formData)
}

const services = {
  good: {
    getgdsrcxx4H5: (params) => request('GDXXPubCenter', 'getgdsrcxx4H5', params)
  },
  news: {
    getNewsInfo4H5: (params) => request('PCNewMgr', 'getNewsInfo4H5', params)
  },
  truck: {
    getTKSrcxx4H5: (params) => request('TKSrcMgr', 'getTKSrcxx4H5', params)
  },
  whmgr: {
    getRealInvenListForH5: (params) => request('WHMgr', 'getRealInvenListForH5', params),
    // 获取初始化信息
    getInitInfo4CCGJ: () => request('WHMgr', 'getInitInfo4CCGJ'),
    // 获取初次渲染数据
    getApplyInfo: (params) => request('WHMgr', 'getApplyInfo', params),
    // 发起申请
    wldwApply: (params) => request('WHMgr', 'WldwApply', params),
    // 取回
    getBackWldwApplyInfo: (params) => request('WHMgr', 'getBackWldwApplyInfo', params),
    // 申请退回
    cancelWldwApplyInfo: (params) => request('WHMgr', 'cancelWldwApplyInfo', params),
    getWhCompList: (params) => request('WHMgr', 'getWhCompList', params),
    // 获取企业货主列表
    getCompGDOwnerList: (params) => request('RzUserMgr', 'getCompGDOwnerList', params),
    // 营业执照OCR识别
    businessLicenseOCR: (params) => request('Ziyun', 'businessLicenseOCR', params)
  },
  user: {
    // 根据电话获取绑定的登录账号及验证码
    getDlmcAndCodeForMyTel: (mytel) => request('WX', 'getDlmcAndCodeForMyTel', { mytel }),
    // 绑定用户微信openid到默默通
    bindWxOpenIDToMmt: (params) => request('WX', 'bindWxOpenIDToMmt', params),
    bindPushUserInfo: (params) => request('WX', 'bindPushUserInfo', params),
    getDlmcAndMyTel: (params) => request('WX', 'getDlmcAndMyTel', params),
    // 获取用户角色信息
    getUserPrivXX: () => request('PrivXXMgr', 'getUserPrivXX')
  },
  yfprice: {
    // 配送方式列表
    getAIPSModelList: () => request('YFPriceAI', 'getAIPSModelList'),
    // 获取运输条件列表
    getAIShipList: () => request('YFPriceAI', 'getAIShipList'),
    // 获取初始化信息
    getInitXX4Comp: () => request('YFPriceAI', 'getInitXX4Comp'),
    // 获取小梦AI推送方式列表
    getAIPushTypeList: () => request('YFPriceAI', 'getAIPushTypeList'),
    // 判断线路是否被认领 for 线路发布
    decideLineClaim: (params) => request('YFPriceAI', 'decideLineClaim', params),
    getVisitorList: () => request('YFPriceAI', 'getVisitorList'),
    addYFPriceInfo: (params) => request('YFPriceAI', 'addYFPriceInfo', params),
    getYFPriceXX4H5: (params) => request('YFPriceAI', 'getYFPriceXX4H5', params)
  },
  dzorder: {
    // 获取对账订单详情数据
    getDZOrderXX4PC: (params) => request('DZJsMgr', 'getDZOrderXX4PC', params),
    // 对账单审核通过
    agreeDZOrder: (params) => request('DZJsMgr', 'agreeDZOrder', params),
    // 对账单确认发起
    qrSendDZOrder4WLSJ: (params) => request('DZJsMgr', 'qrSendDZOrder4WLSJ', params),
    // 对账单取消审核
    getBackCheckDZOrder: (params) => request('DZJsMgr', 'getBackCheckDZOrder', params),
    // 对账单取消对账
    cancelDZOrder: (params) => request('DZJsMgr', 'cancelDZOrder', params),
    // 对账单一键取回
    getBackDZOrder: (params) => request('DZJsMgr', 'getBackDZOrder', params),
    // 对账单退回发起方
    returnDZOrder2CYF: (params) => request('DZJsMgr', 'returnDZOrder2CYF', params),
    // 对账单退回运营方
    returnDZOrder2YYF4HZDJ: (params) => request('DZJsMgr', 'returnDZOrder2YYF4HZDJ', params),
    // 对账单退回上一级
    returnDZOrderPreStep: (params) => request('DZJsMgr', 'returnDZOrderPreStep', params)
  },
  billUpload: {
    // 获取发货单据详情
    getFHOrderDetails: (params) => request('BillUploadMgr', 'getFHOrderDetails', params),
    // 上传工地签收图片信息
    uploadBillData: (params) => request('BillUploadMgr', 'uploadBillData', params),
    // 获取签收详情 For 货主帝家、物流世家、司机之家和运营方
    getSignOrderXX: (params) => request('BillUploadMgr', 'getSignOrderXX', params),
    // 驳回工地签收图片信息
    BHSignBillUpload: (params) => request('BillUploadMgr', 'BHSignBillUpload', params),
    // 复核通过工地签收图片信息
    agreeSignBillUpload: (params) => request('BillUploadMgr', 'agreeSignBillUpload', params),
    // 复核通过工地签收图片信息 for yyf
    agreeSignBillUpload4YYF: (params) => request('BillUploadMgr', 'agreeSignBillUpload4YYF', params)
  },
  fileUpload: {
    // 上传发货图片
    uploadFHOrderBase64Img: (params) => filerequest('ElArcMgr', 'uploadFHOrderBase64Img', params),
    // 上传签收图片
    uploadCusSignBase64Img: (formData) => filerequest('ElArcMgr', 'uploadCusSignBase64Img', formData)
  },
  shorturl: {
    // 通过短码获取链接信息
    getFullUrlXX: (params) => request('ShortUrl', 'getFullUrlXX', params)
  },
  OrderMgr: {
    // 获取订单详情信息
    getOrderDetails: (params) => request('NewTHDOrderMgr', 'getOrderDetails', params),
    // 流转详情
    getDJOperList: (params) => request('NewTHDOrderMgr', 'getDJOperList', params)
  },
  rzmgr: {
    // 获取用户认证信息
    getUserRzXX: (params) => request('RzUserMgr', 'getUserRzXX'),
    // 营业执照OCR识别
    getBusinessOCRxx: (params) => request('Ziyun', 'businessLicenseOCR', params),
    // 身份证OCR识别
    getidCardOCROCRxx: (params) => request('Ziyun', 'idCardOCR', params),
    // 驾驶证OCR识别
    getdrilicenseOCRxx: (params) => request('Ziyun', 'drivingLicenseOCR', params),
    // 从业资格证转url
    getDriverQualifyBase64Img: (params) => request('ElArcMgr', 'uploadDriverQualifyBase64Img', params),
    // 提交货主企业认证
    tjgdrz4comp: (params) => request('RzUserMgr', 'tjgdrz4comp', params),
    // 提交货主个体认证
    tjgdrz4person: (params) => request('RzUserMgr', 'tjgdrz4person', params),
    // 提交司机认证
    tjsjrz: (params) => request('RzUserMgr', 'tjdriverrz', params),
    // 提交物流公司认证
    tjwlsjrz: (params) => request('RzUserMgr', 'tjtrancomprz', params),
    // 提交仓储认证
    tjwhrz4comp: (params) => request('RzUserMgr', 'tjwhrz4comp', params),
    // 获取认证审核中信息
    getunchkRzXX: (params) => request('RzUserMgr', 'getunchkRzXX', params),
    // 获取认证成功后的信息
    getchkedRzXX: (params) => request('RzUserMgr', 'getchkedRzXX', params),
    // 取消认证申请
    endNowRzApp: (params) => request('RzUserMgr', 'endNowRzApp', params),
    // 撤销认证
    cancelRzApp: (params) => request('RzUserMgr', 'cancelRzApp', params),
    // 取消变更资料
    endNowRzChgApp: (params) => request('RzUserMgr', 'endNowRzChgApp', params),
    // 变更资料-DZDJ
    retjgdrz4comp: (params) => request('RzUserMgr', 'retjgdrz4comp', params),
    // 变更资料-WLSJ
    retjtrancomprz: (params) => request('RzUserMgr', 'retjtrancomprz', params),
    // 变更资料-CCGJ
    retjwhrz4comp: (params) => request('RzUserMgr', 'retjwhrz4comp', params),
    // 变更资料-SJZJ
    retjdriverrz: (params) => request('RzUserMgr', 'retjdriverrz', params)
  },
  carmgr: {
    // 行驶证OCR识别
    getVehicleLicenseOCROCRxx: (params) => request('Ziyun', 'vehicleLicenseOCR', params),
    // 道路运输证OCR识别
    getRoadLicenseOCROCRxx: (params) => request('Ziyun', 'roadLicenseOCR', params),
    // 道路经营许可证OCR识别
    getRoadJYXKZBase64Img: (params) => request('ElArcMgr', 'uploadRoadJYXKZBase64Img', params),
    // 司机登记车辆
    regCommonTruckXX: (params) => request('NetTKXXMgr', 'regCommonTruckXX', params),
    // 司机车辆列表
    getTruckList4SJZJ: (params) => request('NetTKXXMgr', 'getTruckList4SJZJ', params),
    // 物流公司登记车辆
    regCommonTruckXX4WLSJ: (params) => request('NetTKXXMgr', 'regCommonTruckXX4WLSJ', params),
    // 物流公司车辆列表
    getTruckList4Other: (params) => request('NetTKXXMgr', 'getTruckList4Other', params),
    // 车辆行驶证详情
    getVehicleLicensesOCR: (params) => request('NetTKXXMgr', 'getVehicleLicensesOCR', params),
    // 车辆道路运输证详情
    getRoadLicenseOCR: (params) => request('NetTKXXMgr', 'getRoadLicenseOCR', params)
  },
  yfsetter: {
    // 获取收款详情
    getSKAppDetails: (params) => request('YZFMgr', 'getSKAppDetails', params),
    // 获取银行列表
    getBankList: (params) => request('OffBankMgr', 'getBankList', params),
    // 发票转url
    uploadEBillBase64Img: (params) => request('ElArcMgr', 'uploadEBillBase64Img', params),
    // 发起收款申请
    qrSendSKApp: (params) => request('YZFMgr', 'qrSendSKApp', params),
    // 审核/重新发起收款
    agreeSKApp: (params) => request('YZFMgr', 'agreeSKApp', params),
    // 一键取回收款
    getBackSKApp: (params) => request('YZFMgr', 'getBackSKApp', params),
    // 取消审核收款
    getBackCheckSKApp: (params) => request('YZFMgr', 'getBackCheckSKApp', params),
    // 获取付款详情
    getFKAppDetails: (params) => request('YZFMgr', 'getFKAppDetails', params),
    // 取消审核付款
    getBackCheckFKApp: (params) => request('YZFMgr', 'getBackCheckFKApp', params),
    // 审核付款
    agreeFKApp: (params) => request('YZFMgr', 'agreeFKApp', params),
    // 退回发起方
    returnFKApp2Send: (params) => request('YZFMgr', 'returnFKApp2Send', params)
  },
  drawLotProjMgr: {
    // 获取活动初始化信息
    getInitXX: (params) => request('DrawLotProjMgr', 'getInitXX', params),
    // 判断用户是否报名
    checkTheUserApp: (params) => request('DrawLotProjMgr', 'checkTheUserApp', params),
    // 提交报名
    tjUserRegApp: (params) => request('DrawLotProjMgr', 'tjUserRegApp', params),
    // 获取用户认证状态
    getTheUserRZAppXX: (params) => request('RZMgr', 'getTheUserRZAppXX', params)
  }
}

export default services
