import Vue from 'vue'
import VueRouter from 'vue-router'
import Nprogress from 'nprogress'
import routes from '@/router/routes'
import 'nprogress/nprogress.css'
import store from '@/store/index'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0, behavior: 'smooth' }
  }
})

router.beforeEach((to, from, next) => {
  Nprogress.start()
  const userInfo = store.getters.userInfo
  const authorities = to.meta && to.meta.authorities
  if (userInfo.username) {
    next()
  } else {
    if (authorities === 'login') {
      next({
        path: '/user/login',
        query: {
          redirect: to.fullPath
        }
      })
      Nprogress.done()
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  Nprogress.done()
})

export default router
