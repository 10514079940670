import axios from 'axios'
import { Dialog } from 'vant'
import router from '@/router/index'
import store from '@/store/index'
import rsaEncrypt from '@/untils/rsa-encrypt'
import { aesEncrypt } from '@/untils/storage'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    'Content-Type': 'text/plain'
  }
})

service.interceptors.request.use(
  config => {
    const userInfo = store.getters.userInfo
    if (config.method === 'post') {
      const { data = {} } = config
      const newDate = new Date()
      const content = 'bochang' + ':' + (newDate.getFullYear() + '-' + ((newDate.getMonth() + 1) < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)) + '-' + (newDate.getDate() < 10 ? ('0' + newDate.getDate()) : newDate.getDate()))
      data.key = rsaEncrypt(content)
      data.session_id = data.session_id || userInfo.session_id
      data.username = data.username || userInfo.username
      if (process.env.NODE_ENV === 'production') config.data = aesEncrypt(data)
      else config.data = JSON.stringify(data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const { data, status } = response
    if (status === 200) {
      if (data.status) {
        return data.info
      } else if (data.status === 0 && data.info === '101') {
        store.commit('CLEAR_USERINFO')
        Dialog.alert({
          title: '温馨提示',
          message: '未登录或登录状态已过期！'
        }).then(() => {
          const { fullPath } = router.currentRoute
          router.push({
            path: '/user/login',
            query: {
              redirect: fullPath
            }
          })
          return Promise.reject(data.info)
        }).catch(() => {})
      } else {
        Dialog.alert({
          title: '温馨提示',
          message: data.info || '接口错误'
        })
        return Promise.reject(data.info)
      }
    }
    return Promise.reject(response)
  },
  error => {
    Dialog.alert({
      message: '网络请求失败！'
    }).then(() => {
      // on close
    })
    return Promise.reject(error)
  }
)

export const fileService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    'Content-type': 'multipart/form-data'
  }
})

fileService.interceptors.request.use(
  config => {
    const userInfo = store.getters.userInfo
    if (config.method === 'post') {
      // console.log(process.env.NODE_ENV === 'production')
      const { data: formData } = config
      const newDate = new Date()
      const content = 'bochang' + ':' + (newDate.getFullYear() + '-' + ((newDate.getMonth() + 1) < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)) + '-' + (newDate.getDate() < 10 ? ('0' + newDate.getDate()) : newDate.getDate()))
      formData.append('key', rsaEncrypt(content))
      formData.append('session_id', userInfo.session_id)
      formData.append('username', userInfo.username)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

fileService.interceptors.response.use(
  response => {
    const { data, status } = response
    if (status === 200) {
      if (data.status) {
        return data.info
      } else if (data.status === 0 && data.info === '101') {
        store.commit('CLEAR_USERINFO')
        Dialog.alert({
          title: '温馨提示',
          message: '未登录或登录状态已过期！'
        }).then(() => {
          const { fullPath } = router.currentRoute
          router.push({
            path: '/user/login',
            query: {
              redirect: fullPath
            }
          })
          return Promise.reject(data.info)
        }).catch(() => {})
      } else {
        Dialog.alert({
          title: '温馨提示',
          message: data.info
        })
        return Promise.reject(data.info)
      }
    }
    return Promise.reject(response)
  },
  error => {
    Dialog.alert({
      message: '网络请求失败！'
    }).then(() => {
      // on close
    })
    return Promise.reject(error)
  }
)

export default service
