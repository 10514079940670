import { getUserInfo, setUserInfo } from '@/untils/storage'

export default {
  state: {
    userInfo: {
      allowoper: '',
      domain: '',
      groupid: '',
      groupname: '',
      icon: '',
      mobphone: '',
      session_id: '',
      uid: '',
      username: '',
      wxopenid: '',
      ...getUserInfo()
    }
  },
  getters: {
  },
  mutations: {
    SET_USERINFO (state, value = {}) {
      const info = { ...state.userInfo, ...value }
      setUserInfo(info)
      state.userInfo = info
    },
    CLEAR_USERINFO (state) {
      const newInfo = {
        allowoper: '',
        domain: '',
        groupid: '',
        groupname: '',
        icon: '',
        mobphone: '',
        session_id: '',
        uid: '',
        username: ''
      }
      const info = { ...state.userInfo, ...newInfo }
      setUserInfo(info)
      state.userInfo = info
    }
  },
  actions: {
  }
}
