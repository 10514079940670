const routes = [
  {
    path: '/',
    meta: {
      title: '首页'
    },
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/IndexView.vue')
  },
  {
    path: '/news',
    meta: {
      title: '新闻'
    },
    component: () => import(/* webpackChunkName: "news" */ '@/views/news/IndexView.vue')
  },
  {
    path: '/good',
    meta: {
      title: '货源中心'
    },
    component: () => import(/* webpackChunkName: "good" */ '@/views/good/IndexView.vue')
  },
  {
    path: '/truck',
    meta: {
      title: '车源中心'
    },
    component: () => import(/* webpackChunkName: "truck" */ '@/views/truck/IndexView.vue')
  },
  {
    path: '/whdetail',
    meta: {
      title: '默默通'
    },
    component: () => import(/* webpackChunkName: "whdetail" */ '@/views/whmgr/detail/IndexView.vue')
  },
  {
    path: '/whmgr/apply/:whid',
    meta: {
      title: '入驻单位申请-发起',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "whmgr-apply" */ '@/views/whmgr/apply/IndexView.vue')
  },
  {
    path: '/whmgr/list',
    meta: {
      title: '入驻单位申请',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "whmgr-list" */ '@/views/whmgr/list/IndexView.vue')
  },
  {
    path: '/bcs',
    meta: {
      title: '默默账簿-密码'
    },
    component: () => import(/* webpackChunkName: "bcs" */ '@/views/bcs/IndexView.vue')
  },
  {
    path: '/qys',
    meta: {
      title: '实名认证'
    },
    component: () => import(/* webpackChunkName: "qys" */ '@/views/qys/IndexView.vue')
  },
  {
    path: '/user/login',
    meta: {
      title: '用户登录-注册'
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/user/login/IndexView.vue')
  },
  {
    path: '/workbook/pub',
    meta: {
      title: '小梦AI发布',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "workbook-pub" */ '@/views/aiworkbook/pub/IndexView.vue')
  },
  {
    path: '/workbook/list/:yfid',
    meta: {
      title: '物流报价',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "workbook-list" */ '@/views/aiworkbook/list/IndexView.vue')
  },
  {
    path: '/dzorder',
    meta: {
      title: '对账详情',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "dzorder" */ '@/views/dzorder/IndexView.vue')
  },
  {
    path: '/sign/:orderid',
    meta: {
      title: '运单签收',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "sign" */ '@/views/sign/IndexView.vue')
  },
  {
    path: '/chksign/:signbillid',
    meta: {
      title: '复核签收',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "chksign" */ '@/views/chksign/IndexView.vue')
  },
  {
    path: '/chksign/yyf/:signbillid',
    meta: {
      title: '复核签收',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "chksign-yyf" */ '@/views/chksign/yyf/IndexView.vue')
  },
  {
    path: '/order_detail',
    meta: {
      title: '查看详情',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "order_detail" */ '@/views/order_detail/IndexView.vue')
  },
  {
    path: '/rzmgr',
    meta: {
      title: '认证中心',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "rzmgr" */ '@/views/rzmgr/IndexView.vue')
  },
  {
    path: '/rzmgr/hzdj',
    meta: {
      title: '货主认证',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "rzmgr" */ '@/views/rzmgr/hzdj/IndexView.vue')
  },
  {
    path: '/rzmgr/sjzj',
    meta: {
      title: '司机认证',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "rzmgr" */ '@/views/rzmgr/sjzj/IndexView.vue')
  },
  {
    path: '/rzmgr/wlsj',
    meta: {
      title: '物流公司认证',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "rzmgr" */ '@/views/rzmgr/wlsj/IndexView.vue')
  },
  {
    path: '/rzmgr/ccgj',
    meta: {
      title: '仓储认证',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "rzmgr" */ '@/views/rzmgr/ccgj/IndexView.vue')
  },
  {
    path: '/carmgr/add_driver',
    meta: {
      title: '登记车辆',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "carmgr" */ '@/views/carmgr/add_driver/IndexView.vue')
  },
  {
    path: '/carmgr/add_comp',
    meta: {
      title: '登记车辆',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "carmgr" */ '@/views/carmgr/add_comp/IndexView.vue')
  },
  {
    path: '/carmgr/carlist',
    meta: {
      title: '我的车辆',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "carmgr" */ '@/views/carmgr/carlist/IndexView.vue')
  },
  {
    path: '/yfsetter/skmgr',
    meta: {
      title: '收款详情',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "carmgr" */ '@/views/yfsetter/skmgr/IndexView.vue')
  },
  {
    path: '/yfsetter/fkmgr',
    meta: {
      title: '付款详情',
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "carmgr" */ '@/views/yfsetter/fkmgr/IndexView.vue')
  },
  {
    path: '/promotion',
    meta: {
      title: '默默通物流'
    },
    component: () => import(/* webpackChunkName: "promotion" */ '@/views/promotion/IndexView.vue')
  },
  {
    path: '/404',
    meta: {
      title: '404'
    },
    component: () => import(/* webpackChunkName: "404" */ '@/views/404/IndexView.vue')
  },
  // 短链接
  {
    path: '*',
    component: () => import(/* webpackChunkName: "surl" */ '@/views/shortcode/IndexView.vue')
  }
]

export default routes
